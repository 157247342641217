<template>
  <div>
    <table class="table is-fullwidth is-striped is-narrow">
      <tbody>
        <tr>
          <td>{{ $t('Components.Carts.CartTotals.Table_Total-excl-tax') }}</td>
          <td class="has-text-right">
            {{ totalExclTax | toCurrency(languageCulture, cart.CurrencyIso) }}
          </td>
          <td width="50">&nbsp;</td>
        </tr>
        <template v-for="total in taxTotals">
          <tr :key="total.taxPercentage" v-if="total.total > 0">
            <td>
              {{ $t('Components.Carts.CartTotals.Table_Total') }}
              {{ total.taxPercentage }}%
            </td>
            <td class="has-text-right">
              {{ total.total | toCurrency(languageCulture, cart.CurrencyIso) }}
            </td>
            <td width="50">&nbsp;</td>
          </tr>
        </template>
      </tbody>
      <tfoot>
        <tr>
          <td>{{ $t('Components.Carts.CartTotals.Table_Total-incl-tax') }}</td>
          <td width="100" class="has-text-right">
            {{ totalInclTax | toCurrency(languageCulture, cart.CurrencyIso) }}
          </td>
          <td width="50">&nbsp;</td>
        </tr>
        <tr v-if="cart.TotalPaid > 0">
          <td>{{ $t('Components.Carts.CartTotals.Table_Amount-paid') }}</td>
          <td width="100" class="has-text-right">
            {{ cart.TotalPaid | toCurrency(languageCulture, cart.CurrencyIso) }}
          </td>
          <td width="50">&nbsp;</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    languageCulture: {
      type: String,
      default: 'nl-NL',
    },
  },

  data() {
    return {
      taxValues: [],
    }
  },

  computed: {
    ...mapState('cartStore', ['cart']),

    totalExclTax() {
      let total = 0

      if (this.cart) {
        for (let i = 0; i < this.cart.Spaces.length; i++) {
          let space = this.cart.Spaces[i]

          total = total + space.PriceTotal
        }

        for (let i = 0; i < this.cart.Options.length; i++) {
          let option = this.cart.Options[i]

          total = total + option.PriceTotal
        }
      }

      return this.roundToTwo(total)
    },

    totalInclTax() {
      let self = this
      let total = 0
      self.taxValues = []

      if (self.cart) {
        for (let i = 0; i < self.cart.Spaces.length; i++) {
          let space = self.cart.Spaces[i]
          let spaceTotalExcl = space.PriceTotal
          let spaceTotalIncl = self.roundToTwo(
            spaceTotalExcl * ((100 + space.TaxPercentage) / 100)
          )

          self.taxValues.push({
            TaxPercentage: space.TaxPercentage,
            Amount: self.roundToTwo(spaceTotalIncl - spaceTotalExcl),
          })

          total = total + spaceTotalIncl
        }

        for (let i = 0; i < self.cart.Options.length; i++) {
          let option = self.cart.Options[i]
          let optionTotalExcl = option.PriceTotal
          let optionTotalIncl = self.roundToTwo(
            optionTotalExcl * ((100 + option.TaxPercentage) / 100)
          )

          self.taxValues.push({
            TaxPercentage: option.TaxPercentage,
            Amount: self.roundToTwo(optionTotalIncl - optionTotalExcl),
          })

          total =
            total +
            self.roundToTwo(
              option.PriceTotal * ((100 + option.TaxPercentage) / 100)
            )
        }
      }

      return self.roundToTwo(total)
    },

    taxTotals() {
      let self = this
      let result = []

      self.taxValues.reduce(function(res, value) {
        if (!res[value.TaxPercentage]) {
          res[value.TaxPercentage] = {
            taxPercentage: value.TaxPercentage,
            total: 0,
          }
          result.push(res[value.TaxPercentage])
        }
        res[value.TaxPercentage].total = self.roundToTwo(
          res[value.TaxPercentage].total + value.Amount
        )
        return res
      }, {})

      return result.sort((r) => r.TaxPercentage)
    },
  },

  methods: {
    roundToZero(num) {
      let self = this
      return +(Math.round(self.roundToTwo(num) + 'e+0') + 'e-0')
    },

    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2')
    },
  },
}
</script>
